import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import AuraLogoImg from '../assets/images/aura-logo.png'
import { chainInfo } from '../config'
import Container from './Container'
const Root = styled.div`
    position: fixed;
    width: 100%;
    z-index: 99;
`
const Wrap = styled.div`
    background: linear-gradient(269.9deg, rgba(34, 34, 34, 0.35) 0%, rgba(49, 49, 49, 0.35) 100.72%);
    border-radius: 0px 0px 24px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 12px;
    > img {
        cursor: pointer;
    }
    > .nav-bar {
        display: flex;
        > a {
            color: #f4f4f4;
            text-decoration: none;
            margin: 0px 20px;
            cursor: pointer;
        }
    }
    @media only screen and (max-width: 1000px) {
        > img {
            height: 25px;
        }
        > .nav-bar {
            > a {
                font-size: 12px;
                line-height: 13px;
                margin: 0px 5px;
            }
        }
    }
    .connect-wallet {
        cursor: pointer;
        border: 1px solid transparent;
        background-image: linear-gradient(rgb(19, 20, 25), rgb(19, 20, 25)),
            linear-gradient(108.46deg, rgb(94, 230, 208) 12.51%, rgb(191, 198, 255) 51.13%, rgb(255, 186, 105) 87.49%);
        background-origin: border-box;
        background-clip: content-box, border-box;
        border-radius: 99px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.01em;
        color: rgb(255, 255, 255);
        white-space: nowrap;
        background-color: transparent !important;
        padding: 0px !important;
        min-width: 150px;
        > div {
            padding: 10px 24px;
            border-radius: 99px;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64)),
                linear-gradient(
                    113.1deg,
                    rgb(94, 230, 208) 13.45%,
                    rgb(191, 198, 255) 50.33%,
                    rgb(255, 186, 105) 85.05%
                );
        }
    }
`
export default function Header() {
    const [walletAddress, setWalletAddress] = useState('')
    const navigate = useNavigate()
    useEffect(() => {
        if (window.localStorage.getItem('isConnected')) {
            connectWallet()
        }
        // eslint-disable-next-line
    }, [])

    const connectWallet = async () => {
        if (!window.keplr && !window.coin98?.keplr) {
            alert('Please install Keplr wallet extension.')
        } else {
            const keplr = window.coin98?.keplr ? window.coin98?.keplr : window.keplr
            if (walletAddress) {
                setWalletAddress('')
                window.localStorage.removeItem('isConnected')
            } else {
                await keplr.experimentalSuggestChain(chainInfo)
                await keplr.enable(chainInfo.chainId)
                const key = await keplr.getKey(chainInfo.chainId)
                window.localStorage.setItem('isConnected', '1')
                setWalletAddress(key.bech32Address)
            }
        }
    }
    return (
        <Root>
            <Container>
                <Wrap>
                    <img onClick={() => navigate('/')} src={AuraLogoImg} alt='' className='logo-img' />
                    <div className='nav-bar'></div>
                    <button className='connect-wallet' onClick={connectWallet}>
                        {walletAddress ? (
                            <div>{`${walletAddress.slice(0, 4)}...${walletAddress.slice(
                                walletAddress.length - 6,
                                walletAddress.length
                            )}`}</div>
                        ) : (
                            <div>Connect Wallet</div>
                        )}
                    </button>
                </Wrap>
            </Container>
        </Root>
    )
}
