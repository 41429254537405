import styled from 'styled-components'

export const Wrap = styled.div`
    max-width: 1160px;
    margin: auto;
    position: relative;
    z-index: 1;
    @media only screen and (max-width: 1280px) {
        max-width: 960px;
    }
    @media only screen and (max-width: 1000px) {
        margin: 0px 26px;
        max-width: unset;
    }
`
export default function Container({ children, style, id, className }) {
    return (
        <Wrap className={className} id={id} style={style}>
            {children}
        </Wrap>
    )
}
