import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { gameData } from '../../config'
const Wrap = styled.div`
    cursor: pointer;
    img {
        margin-bottom: 19px;
        width: 100%;
        height: 480px;
        border-radius: 10px;
        object-fit: cover;
    }
    .title {
        font-weight: 600;
        font-size: 20px;
        line-height: 21px;
        margin-bottom: 4px;
    }
    
    .genre {
        font-weight: 400;
        font-size: 16px;
        line-height: 17px;
        margin-bottom: 4px;

        color: #5578f1;
    }

    background: linear-gradient(304.78deg, rgba(245, 251, 242, 0.44) -6.31%, rgba(245, 251, 242, 0) 110.8%);
    padding: 1px;
    border-radius: 15px;
    > div {
        background: #08071b;
        padding: 15px;
        border-radius: 15px;
    }
    @media only screen and (max-width: 1000px) {
        .title {
            font-weight: 600;
            font-size: 20px;
            line-height: 21px;
        }
    }
`
export default function Game({ id }) {
    const navigate = useNavigate()
    return (
        <Wrap onClick={() => navigate(`/${id}`)}>
            <div>
                <img src={gameData[id].image} alt='game' />
                <p className='title'>{gameData[id].name}</p>
                <p className='genre'>Action, Puzzle</p>
            </div>
        </Wrap>
    )
}
