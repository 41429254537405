import styled from 'styled-components'
import Container from '../Container'
import Game from './Game'
import Background from '../../assets/images/bg-img.png'
const Root = styled.div`
    background-image: url(${Background});
    background-repeat: no-repeat;
    padding: 100px 0px;
    background-size: 100% 100%;
`
const Wrap = styled.div`
    > div > .title {
        font-weight: 600;
        font-size: 40px;
        line-height: 42px;
        /* identical to box height */

        color: #f4f4f4;
    }
    .games {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 22px;
    }
    > div:first-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        > button {
            outline: none;
            background: transparent;
            color: #5578f1;
            cursor: pointer;
            border: none;
        }
    }
    @media only screen and (max-width: 1000px) {
        .games {
            grid-template-columns: 1fr;
            gap: 22px;
            margin-top: 20px;
        }

        > div:first-child {
            display: unset;
            .title {
                font-weight: 600;
                font-size: 24px;
                line-height: 25px;
                text-align: center;

                color: #f4f4f4;
            }
            button {
                display: none;
            }
        }
    }
`
export default function OurGames() {
    return (
        <Root>
            <Container id='games'>
                <Wrap>
                    <div>
                        <p className='title'>Our Games</p>
                        <button>See All</button>
                    </div>
                    <div className='games'>
                        <Game id='diamond' />
                        <Game id='miner' />
                        <Game id='stampit' />
                        <Game id='fallguy' />
                        <Game id='glasschallenge' />
                        <Game id='candyunblock' />
                    </div>
                </Wrap>
            </Container>
        </Root>
    )
}
