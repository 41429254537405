import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import styled from 'styled-components'
import Candy from '../../assets/images/candy__large.png'
import FallGuy from '../../assets/images/fallguy__large.png'
import Glass from '../../assets/images/glass__large.png'
import Miner from '../../assets/images/miner__large.png'
import Backdrop from '../../assets/images/spotlight-backdrop.svg'
import BackdropCta from '../../assets/images/spotlight-cta.svg'
import Stamp from '../../assets/images/stampit__large.png'
import Container from '../Container'
import LogoSlider from './LogoSlider'
const Root = styled.div`
    position: relative;
    > .logo-slider {
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
    }
`
const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    > .spotlight {
        background-image: url(${Backdrop});
        padding: 12px;
        background-repeat: no-repeat;
        > .slider {
            width: calc(100vw - 32px);
            max-width: 714px;
        }
    }
    .cta {
        background-image: url(${BackdropCta});
        background-repeat: no-repeat;
        font-weight: 400;
        font-size: 31.5593px;
        line-height: 111.5%;
        width: 317px;
        height: 63px;
        margin-top: -14px;
        text-align: center;
        letter-spacing: 0.11em;
        text-transform: capitalize;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px auto 8px;
        cursor: pointer;
        &:hover {
            filter: brightness(1.08);
        }
    }
    @media only screen and (max-width: 1000px) {
        .spotlight {
            .slider {
                height: 382px;
                max-width: 314px;
                object-fit: cover;
            }
        }
        .cta {
            width: 140px;
            height: 31px;
            font-weight: 400;
            font-size: 13.959px;
            line-height: 111.5%;
            margin-top: -15px;

            text-align: center;
            letter-spacing: 0.11em;
            text-transform: capitalize;
            background-size: contain;
        }
    }
`
function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return (
        <div className={className} style={{ ...style, display: 'block', right: '10px', zIndex: 2 }} onClick={onClick} />
    )
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return (
        <div className={className} style={{ ...style, display: 'block', left: '10px', zIndex: 2 }} onClick={onClick} />
    )
}
export default function Spotlight() {
    const [dragging, setDragging] = useState(false)

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        beforeChange: () => setDragging(true),
        afterChange: () => setDragging(false),
    }
    const navigate = useNavigate()

    return (
        <Root>
            <Container>
                <Wrap>
                    <div className='spotlight'>
                        <div className='slider'>
                            <Slider {...settings}>
                                <div>
                                    <img onClick={() => (dragging ? null : navigate(`/miner`))} src={Miner} alt='' />
                                </div>
                                <div>
                                    <img onClick={() => (dragging ? null : navigate(`/stampit`))} src={Stamp} alt='' />
                                </div>
                                <div>
                                    <img
                                        onClick={() => (dragging ? null : navigate(`/glasschallenge`))}
                                        src={Glass}
                                        alt=''
                                    />
                                </div>
                                <div>
                                    <img
                                        onClick={() => (dragging ? null : navigate(`/fallguy`))}
                                        src={FallGuy}
                                        alt=''
                                    />
                                </div>
                                <div>
                                    <img
                                        onClick={() => (dragging ? null : navigate(`/candyunblock`))}
                                        src={Candy}
                                        alt=''
                                    />
                                </div>
                            </Slider>
                        </div>
                    </div>
                    <div
                        className='cta'
                        onClick={() => document.getElementById('games').scrollIntoView({ behavior: 'smooth' })}>
                        Play now
                    </div>
                </Wrap>
            </Container>
            <div className='logo-slider'>
                <LogoSlider />
            </div>
        </Root>
    )
}
