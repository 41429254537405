import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Slider from 'react-slick'
import styled from 'styled-components'
import PlayButton from '../../assets/images/play.png'
import { gameData } from '../../config'
import Container from '../Container'
import Footer from '../Footer'
import Gap from '../Gap'
import Header from '../Header'
import Game from './Game'
import Background from '../../assets/images/bg-img.png'

const Root = styled.div`
    background-image: url(${Background});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`

const Wrap = styled.div`
    background: rgb(8 7 27 / 82%);
    color: #f4f4f4;
    iframe {
        width: ${(props) => props.width}px;
        border: none;
        height: ${(props) => props.height}px;
    }
    #game-iframe {
        display: flex;
        justify-content: center;
        > .gameplay-btn {
            position: absolute;
            bottom: 32px;
            right: 32px;
            border: none;
            font-size: 20px;
            padding: 12px;
            border-radius: 99px;
            background: #36b5ff;
            cursor: pointer;
        }
        > .back-btn {
            position: absolute;
            top: 32px;
            left: 32px;
            border: none;
            font-size: 20px;
            padding: 12px;
            border-radius: 99px;
            background: #36b5ff;
            cursor: pointer;
        }
    }
    > .image {
        padding: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        &::after {
            content: '';
            background-image: url(${(props) => gameData[props.gameId].image});
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 0;
            filter: blur(30px);
            opacity: 0.6;
        }
        > img {
            z-index: 1;
        }
        > img:first-child {
            height: 600px;
        }
        .play-button {
            position: absolute;
            z-index: 3;
            cursor: pointer;
            bottom: 184px;
            left: 50%;
            transform: translateX(-28px);
            &:hover {
                transform: translateX(-28px) scale(1.08);
            }
        }
    }
    .name {
        font-weight: 600;
        font-size: 48px;
        line-height: 50px;
        margin-top: 58px;
    }
    .description {
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        margin-top: 10px;
    }
    .control {
        font-weight: 600;
        font-size: 32px;
        line-height: 34px;
        margin-top: 50px;
        > p {
            font-weight: 400;
            font-size: 24px;
            line-height: 36px;
            margin-top: 10px;
        }
    }
    .more-games > .title {
        font-weight: 600;
        font-size: 40px;
        line-height: 42px;
        margin-bottom: 16px;
        /* identical to box height */

        color: #f4f4f4;
    }
    @media only screen and (max-width: 1000px) {
    }
`
export default function GameDetail() {
    const { gameId } = useParams()
    const [played, setPlayed] = useState(false)
    const [width, setWidth] = useState(window.innerWidth)
    const [height, setHeight] = useState(window.innerHeight)
    const [dragging, setDragging] = useState(false)
    useEffect(() => {
        window.scrollTo(0, 0)
        const ratio = gameData[gameId].ratio
        //case full width
        const expectedHeight = Math.floor(window.innerWidth / ratio)
        if (expectedHeight <= window.innerHeight) {
            setHeight(expectedHeight)
            return
        }
        //case full height
        const expectedWidth = Math.floor(window.innerHeight * ratio)
        if (expectedWidth <= window.innerWidth) {
            setWidth(expectedWidth)
            return
        }
    }, [gameId])
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        swipeToSlide: true,
        arrows: false,
        beforeChange: () => setDragging(true),
        afterChange: () => setDragging(false),
    }
    return (
        <Root>
            <Wrap height={height} width={width} gameId={gameId}>
                {!played && <Header />}
                {played ? (
                    <div id='game-iframe'>
                        <button className='back-btn' onClick={() => (window.location.href = '/')}>
                            Back
                        </button>
                        <iframe src={gameData[gameId].link} title={gameData[gameId].name} />
                        <button
                            className='gameplay-btn'
                            onClick={() =>
                                document.getElementById('game-play').scrollIntoView({ behavior: 'smooth' }, true)
                            }>
                            Gameplay
                        </button>
                    </div>
                ) : (
                    <div className='image'>
                        <img src={gameData[gameId].image} alt='' />
                        <img src={PlayButton} alt='' className='play-button' onClick={() => setPlayed(true)} />
                    </div>
                )}
                <Container id='game-play'>
                    <p className='name'>{gameData[gameId].name}</p>
                    <p className='description' dangerouslySetInnerHTML={{ __html: gameData[gameId].description }}></p>
                    <div className='control'>
                        Control
                        <p dangerouslySetInnerHTML={{ __html: gameData[gameId].control }}></p>
                    </div>
                </Container>
                <Gap height={100} />
                <Container className='more-games'>
                    <p className='title'>More Games</p>
                    <Slider {...settings}>
                        {Object.keys(gameData)
                            .filter((key) => key !== gameId)
                            .map((key,index) => {
                                return <Game key={index} isDragging={dragging} id={key} />
                            })}
                    </Slider>
                </Container>
                <Gap height={100} />
                <Footer />
                <Gap height={100} />
            </Wrap>
        </Root>
    )
}
