import Gem from '../src/assets/images/game-diamond.png'
import Miner from '../src/assets/images/game-miner.png'
import StampIt from '../src/assets/images/game-stampit.png'
import FallGuy from '../src/assets/images/game-fallguy.png'
import GlassChallenge from '../src/assets/images/game-glass.png'
import Candy from '../src/assets/images/game-candy.png'

export const gameData = {
    diamond: {
        name: 'GEM',
        link: 'https://dev.akaverse.net/games/diamond/index.html',
        image: Gem,
        ratio: 0.5638859556494192,
        description:
            'An addictive game for who loves classic puzzles. Link 3 or more diamonds of the same color to form a complete line, either vertically or horizontally. As you start with a certain time limit, try to be quick and match as many as possible for higher score.<br/> With its vibrant graphics and exciting gameplay, Diamond is sure to challenge your mind and keep you engaged for hours on end! ',
        control: '- To move diamond: Left-click on gem to drag it to the desired location. ',
    },
    miner: {
        name: 'Miner',
        link: 'https://dev.akaverse.net/games/miner/index.html',
        image: Miner,
        ratio: 1.65,
        description:
            "A thrilling game that puts you in the shoes of a miner. Let's explore deep underground tunnels and try to collect as many minerals as possible. The game features a variety of precious treasures, including gold, diamonds and other power-ups that help you level up faster.<br/> Remember to avoid rocks while gathering your treasures. The more you earn, the faster you move to the next level.",
        control: `- To drop the claw: Down arrow key/ Left-click on dirt<br/>
  - To toss the dynamite: Up arrow key/ Left-click on the dynamite<br/>
  - To get started, please switch to landscape mode`,
    },
    stampit: {
        name: 'Stampit',
        link: 'https://dev.akaverse.net/games/stampIt/index.html',
        image: StampIt,
        ratio: 2.027455121436114,
        description: `An ultimate casual puzzle game that is perfect for all ages. Try to collect all the ink bottles and match stamps on the postcard with the correct spot.<br/> Stamp It is simple to play, but challenging to master. Get immersed in the game, hone your wits and improve your matching skill! `,
        control: `- To turn left: Press A<br/>
- To turn right: Press D<br/>
- To go up: Press W<br/>
- To go down: Press S`,
    },
    fallguy: {
        name: 'Fall Guy',
        link: 'https://dev.akaverse.net/games/fallguy/index.html',
        image: FallGuy,
        ratio: 2.027455121436114,
        description: `A challenging game that require excellent hand-eye coordination. Choose a character to go with and guide it throughout the way. Try to avoid dangerous obstacles such as spinning blades, moving platforms, and other hazards that can knock you off the course.<br/> Looking for an action-packed game that can test your reflexes? Try out Fall Guy now! `,
        control: `- To unloose/tighten the magnet: Left-click on screen`,
    },
    glasschallenge: {
        name: 'Glass Challenger',
        link: 'https://dev.akaverse.net/games/glasschallenge/index.html',
        image: GlassChallenge,
        ratio: 2.027455121436114,
        description: `Welcome to the ultimate challenge for true Squid Game fans: the Tempered Glass Game! Are you ready to test your limits and face your fears? Walk across a bridge made of fragile tempered glass panels, and try to reach the other side as fast as possible. One false move, and you'll plummet to your certain death.<br/> This game will push you to your limits, testing your reflexes and daring you to take risks. It's a true test of nerves and determination, and only the bravest will emerge victorious. Join us and experience this thrill ride. `,
        control: `- To jump: Left-click on the side that you want to move `,
    },
    candyunblock: {
        name: 'Candy Unblock',
        link: 'https://dev.akaverse.net/games/candyunblock/index.html',
        image: Candy,
        ratio: 0.7032734952481521,
        description: `Get ready to test your logic and spatial reasoning skills with this addictive game! Enter a world filled with sweet treats and collect the candy pieces on the shelf into the basket below. All you need to do is slide the glass door to unleash all the sweeties inside.<br/> This game starts out relatively easy. But be warned, the challenges increase with each level, and you'll have to use all your wits to find the best way to clear a path for the sweets.`,
        control: `- To slide the glass door: Left-click on glass and slide`,
    },
}
export const chainInfo = {
    chainId: 'xstaxy-1',
    chainName: 'Aura Mainnet',
    rpc: 'https://rpc.aura.network',
    rest: 'https://lcd.aura.network',
    bip44: {
        coinType: 118,
    },
    bech32Config: {
        bech32PrefixAccAddr: 'aura',
        bech32PrefixAccPub: 'aurapub',
        bech32PrefixValAddr: 'auravaloper',
        bech32PrefixValPub: 'auravaloperpub',
        bech32PrefixConsAddr: 'auravalcons',
        bech32PrefixConsPub: 'auravalconspub',
    },
    currencies: [
        {
            coinDenom: 'AURA',
            coinMinimalDenom: 'uaura',
            coinDecimals: 6,
        },
    ],
    feeCurrencies: [
        {
            coinDenom: 'AURA',
            coinMinimalDenom: 'uaura',
            coinDecimals: 6,
        },
    ],
    stakeCurrency: {
        coinDenom: 'AURA',
        coinMinimalDenom: 'uaura',
        coinDecimals: 6,
    },
    coinType: 118,
    gasPriceStep: {
        low: 0.001,
        average: 0.0025,
        high: 0.004,
    },
    features: ['ibc-transfer'],
    walletUrlForStaking: 'https://aurascan.io/validators',
    logo: 'https://i.imgur.com/zi0mTYb.png',
    explorer: 'https://aurascan.io/',
}
