import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import styled from 'styled-components'
import './App.css'
import Container from './components/Container'
import Footer from './components/Footer'
import Gap from './components/Gap'
import Header from './components/Header'
import OurGames from './components/OurGames'
import Spotlight from './components/Spotlight'
import TextDecor from './assets/images/text-decor.svg'
import TextDecor2 from './assets/images/text-decor-2.svg'
const Wrap = styled.div``
const Title = styled.div`
    max-width: fit-content;
    margin: auto;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    > p:first-child {
        font-size: 86px;
    }
    > p:last-child {
        font-size: 44px;
        margin-top: 10px;
    }

    &::before {
        content: '';
        background: url(${TextDecor2});
        position: absolute;
        width: 77px;
        height: 77px;
        background-repeat: no-repeat;
        top: -48px;
        left: -40px;
        @media only screen and (max-width: 1000px) {
            display: none;
        }
    }
    &::after {
        content: '';
        background: url(${TextDecor});
        position: absolute;
        width: 78px;
        height: 82px;
        background-repeat: no-repeat;
        bottom: 25px;
        right: -68px;
        @media only screen and (max-width: 1000px) {
            display: none;
        }
    }
    @media only screen and (max-width: 1000px) {
        > p:first-child {
            font-size: 46px;
        }
        > p:last-child {
            font-size: 24px;
            margin-top: 10px;
        }
    }
`
function App() {
    return (
        <Wrap className='App'>
            <Header />
            <Gap height={100} />

            <Container>
                <Title>
                    <p>Aura Play</p>
                    <p>Where the fun never dumps!</p>
                </Title>
            </Container>
            <Spotlight />
            <OurGames />
            <Gap height={50} />
            <Footer />
            <Gap height={50} />
        </Wrap>
    )
}

export default App
