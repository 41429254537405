import styled from 'styled-components'
import AuraLogoImg from '../../assets/images/aura-logo.png'
import AuraLogoLightImg from '../../assets/images/aura-logo-light.png'

const Wrap = styled.div`
    display: flex;
    z-index: 0;
    position: absolute;
    top: 50%;
    width: 120%;
    margin-left: -10%;
    div {
        display: flex;
        position: relative;
        animation: marquee 70s linear infinite;
        animation-direction: ${(props) => (props.type === 'black' ? 'reverse' : 'normal')};;
        justify-content: space-around;
        padding: 28px 0px;
    }
    img {
        display: block;
        height: 34px;
        margin: 0px 18px;
    }
    @keyframes marquee {
        0% {
            transform: translateX(0%);
        }
        100% {
            transform: translateX(-100%);
        }
    }
    > div {
        background: ${(props) =>
            props.type === 'black' ? 'rgba(255, 255, 255, 0.05)' : 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFFFFF'};
    }
    transform: ${(props) => (props.type === 'black' ? 'rotate(3.22deg)' : 'rotate(-9.51deg)')};
    @media only screen and (max-width: 1000px) {
        img {
            height: 14px;
            margin: 0px 8px;
        }

        div {
            padding: 10px 0px;
        }
    }
`
export default function LogoSlider() {
    return (
        <>
            <Wrap type='black'>
                <div>
                    {[...Array(Math.ceil(window.innerWidth / 50)).keys()].map((key, index) => (
                        <img key={index} src={AuraLogoImg} alt='' />
                    ))}
                </div>
                <div>
                    {[...Array(Math.ceil(window.innerWidth / 50)).keys()].map((key, index) => (
                        <img key={index} src={AuraLogoImg} alt='' />
                    ))}
                </div>
            </Wrap>
            <Wrap type='white'>
                <div>
                    {[...Array(Math.ceil(window.innerWidth / 50)).keys()].map((key, index) => (
                        <img key={index} src={AuraLogoLightImg} alt='' />
                    ))}
                </div>
                <div>
                    {[...Array(Math.ceil(window.innerWidth / 50)).keys()].map((key, index) => (
                        <img key={index} src={AuraLogoLightImg} alt='' />
                    ))}
                </div>
            </Wrap>
        </>
    )
}
